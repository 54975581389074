/* You can add global styles to this file, and also import other style files */
@import "./assets/tailwind.css";

@import '~@angular/cdk/overlay-prebuilt.css';

$color-primary-blue: #124284;
$color-primary-red: #E30011;

body {
  // background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAAABAgMAAAAeHTDDAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAlQTFRFAAAAEkKE4wARTGEuHgAAAAN0Uk5TAP//RFDWIQAAABFJREFUeJxjYA1FAgEMDFoLADDDBRw/eVWdAAAAAElFTkSuQmCC');
  // background-position: left;
  // background-repeat: repeat-y;

  font-family: Arial, Helvetica, sans-serif !important;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.prose.prose-red {
  a {
    color: $color-primary-red !important;
  }
}

.prose.prose-blue {
  a {
    color: $color-primary-blue !important;
  }
}

.prose {
  // Better UX/UI without flash between changing routes
  min-height: 400px !important;

  h5 {
    font-weight: bold;
    margin-bottom: -1.25rem;
  }
}

.bg-primary-blue {
  background-color: $color-primary-blue;
}

.text-primary-blue {
  color: $color-primary-blue;
}

.bg-primary-red {
  background-color: $color-primary-red;
}

.text-primary-red {
  color: $color-primary-red !important;
}

.hover:bg-primary-blue {
  &:hover {
    background-color: $color-primary-blue;
  }
}

.hover-text-primary-blue:hover {
  color: $color-primary-blue;
}

html.a11y {
  &.grey-screen {
    filter: grayscale(100%)!important;
    -webkit-filter: grayscale(100%)!important;

    h2 {
      background-color: grey !important;
      color: white !important;
      // margin-top: 1em !important;
      // padding-top: 1em !important;
      // padding-bottom: 1em !important;
    }
  }

  &.high-contrast {
    background-color: black !important;
  }
  &.high-contrast * {
    background-color: black !important;
    border-color: rgb(255, 255, 255) !important;
    // color: rgb(80, 208, 160) !important;
    color: #40C090 !important;

    a {
      // color: rgb(252, 255, 60) !important;
      color: #0080FF !important;
    }

    img {
      background-color: rgb(85, 85, 85) !important;
    }

    .g-template, .accessibility-tools-wrapper {
      background-color: unset !important;
    }

    .accessibility-tools-menu-wrapper {
      background-color: black !important;
      border-top: 1px solid #F10101 !important;
      border-left: 1px solid #F10101 !important;
      border-bottom: 1px solid #F10101 !important;

      .menu-title {
        background-color: black !important;
      }

      ul {
        background-color: black !important;
        li {
          background-color: black !important;
        }
      }
    }
  }

  &.negative-contrast {
    background-color: black !important;

    // .accessibility-tools-menu-wrapper {

    // }
  }
  &.negative-contrast * {
    background-color: black !important;
    border-color: rgb(255, 255, 255) !important;
    color: white !important;

    a {
      color: yellow !important;
    }

    // img {
    //   background-color: rgb(85, 85, 85) !important;
    // }

    article {
      small {
        color: yellow !important;
      }
    }

    .g-template {
      background-color: unset !important;
    }

    .accessibility-tools-menu-wrapper {
      background-color: black !important;
      border-top: 1px solid #F10101 !important;
      border-left: 1px solid #F10101 !important;
      border-bottom: 1px solid #F10101 !important;

      .menu-title {
        background-color: black !important;
      }

      ul {
        background-color: black !important;
        li {
          background-color: black !important;
        }
      }
    }
  }

  // &.negative-contrast {
  //   filter: invert(100%);
  //   -webkit-filter: invert(100%);
  // }

  &.light-background {
    background-color: white !important;
  }
  &.light-background * {
    background-color: white !important;
    border-color: rgb(0, 0, 0) !important;
    color: rgb(0, 0, 0) !important;

    a {
      color: rgb(0, 0, 211) !important;
    }

    img {
      background-color: rgb(211, 211, 211) !important;
    }

    .g-template, .accessibility-tools-wrapper {
      background-color: unset !important;
    }

  }

  &.underline-links * {
    a {
      text-decoration: underline !important;
    }
  }

}
